<template>
  <b-sidebar
    id="add-new-style-sidebar"
    :visible="isAddNewStyleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-style-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Style
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <b-alert
        v-model="draftLimitReached"
        variant="danger"
        class="p-1"
      >
        The maximum number of Styles as DRAFT (10) has been reached.
        Please SEND some Styles for revision first.
      </b-alert>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >

          <!-- Brand Reference -->
          <validation-provider
            #default="validationContext"
            name="Ref"
            rules="required"
          >
            <b-form-group
              label="Brand Ref *"
              label-for="ref"
            >
              <b-form-input
                id="ref"
                v-model="styleData.ref"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="styleData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Manufacturer Ref -->
          <b-form-group
            label="Manufacturer Ref"
            label-for="manufacturer-ref"
          >
            <b-form-input
              id="manufacturer-ref"
              v-model="styleData.manufacturer_ref"
              trim
            />
          </b-form-group>

          <!-- Category -->
          <validation-provider
            #default="validationContext"
            name="Category"
            rules="required"
          >
            <b-form-group
              label="Category *"
              label-for="category"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="styleData.category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categoryOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="category"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Brand -->
          <validation-provider
            v-if="userRole !== 'brand-manager' && userRole !== 'brand'"
            #default="validationContext"
            name="Brand"
            rules="required"
          >
            <b-form-group
              label="Brand *"
              label-for="brand"
            >
              <v-select
                v-model="styleData.brand"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="brandOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="brand"
                @input="refreshBrandOptions"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Brand Contact -->
          <b-form-group
            label="Brand Contact"
            label-for="brand-contact-user"
          >
            <v-select
              v-model="styleData.brand_contact_user"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="brandContactUserFilteredOptions"
              :reduce="val => val.value"
              :clearable="true"
              input-id="brand-contact-user"
            />
          </b-form-group>

          <!-- Collection -->
          <b-form-group
            label="Collection"
            label-for="collection"
          >
            <v-select
              v-model="styleData.collection"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="collectionFilteredOptions"
              :reduce="val => val.value"
              :clearable="true"
              input-id="collection"
            />
          </b-form-group>

          <!-- Manufacturer -->
          <b-form-group
            v-if="userRole !== 'manufacturer-manager' && userRole !== 'manufacturer'"
            label="Manufacturer"
            label-for="manufacturer"
          >
            <v-select
              v-model="styleData.manufacturer"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="manufacturerOptions"
              :reduce="val => val.value"
              :clearable="true"
              input-id="manufacturer"
              @input="refreshManufacturerOptions"
            />
          </b-form-group>

          <!-- Manufacturer Contact -->
          <b-form-group
            label="Manufacturer Contact"
            label-for="manufacturer-contact-user"
          >
            <v-select
              v-model="styleData.manufacturer_contact_user"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="manufacturerContactUserFilteredOptions"
              :reduce="val => val.value"
              :clearable="true"
              input-id="manufacturer-contact-user"
            />
          </b-form-group>

          <!-- Agent -->
          <b-form-group
            v-if="userRole !== 'agent-manager' && userRole !== 'agent'"
            label="Agent"
            label-for="agent"
          >
            <v-select
              v-model="styleData.agent"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="agentOptions"
              :reduce="val => val.value"
              :clearable="true"
              input-id="agent"
              @input="refreshAgentOptions"
            />
          </b-form-group>

          <!-- Agent Contact -->
          <b-form-group
            label="Agent Contact"
            label-for="agent-contact-user"
          >
            <v-select
              v-model="styleData.agent_contact_user"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="agentContactUserFilteredOptions"
              :reduce="val => val.value"
              :clearable="true"
              input-id="agent-contact-user"
            />
          </b-form-group>

          <!-- Cover -->
          <validation-provider
            #default="validationContext"
            name="Cover"
            :rules="`size:${25 * 1024 * 1024}`"
          >
            <b-form-group
              label="Cover"
              label-for="cover"
            >
              <b-form-file
                v-model="styleData.cover"
                placeholder="Choose file or drop it here..."
                size="sm"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid || draftLimitReached"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert, BSidebar, BForm, BFormFile, BFormGroup, BFormInput,
  BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewStyleSidebarActive',
    event: 'update:is-add-new-style-sidebar-active',
  },
  props: {
    isAddNewStyleSidebarActive: {
      type: Boolean,
      required: true,
    },
    draftLimitReached: {
      type: Boolean,
      required: true,
    },
    categoryOptions: {
      type: Array,
      required: true,
    },
    brandOptions: {
      type: Array,
      required: true,
    },
    collectionOptions: {
      type: Array,
      required: true,
    },
    brandContactUserOptions: {
      type: Array,
      required: true,
    },
    agentOptions: {
      type: Array,
      required: true,
    },
    agentContactUserOptions: {
      type: Array,
      required: true,
    },
    manufacturerOptions: {
      type: Array,
      required: true,
    },
    manufacturerContactUserOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const blankStyleData = {
      ref: '',
      name: '',
      category: '',
      collection: '',
      agent: '',
      agent_contact_user: '',
      brand: '',
      brand_contact_user: '',
      manufacturer: '',
      manufacturer_contact_user: '',
    }
    const styleData = ref(JSON.parse(JSON.stringify(blankStyleData)))
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const collectionFilteredOptions = ref([])
    const agentContactUserFilteredOptions = ref([])
    const brandContactUserFilteredOptions = ref([])
    const manufacturerContactUserFilteredOptions = ref([])

    const validatorSize = (file, [fileSize]) => {
      const validSize = file.size < +fileSize
      const sizeMB = +fileSize / (1024 * 1024)
      return validSize || `File ${file.name} too big (> ${sizeMB}MB)`
    }

    const size = extend('size', {
      validate: validatorSize,
    })

    const resetStyleData = () => {
      styleData.value = JSON.parse(JSON.stringify(blankStyleData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetStyleData)

    const onSubmit = () => {
      const styleFormData = new FormData()
      Object.keys(styleData.value).forEach(
        key => styleFormData.append(key, styleData.value[key]),
      )
      store.dispatch('app-style/addStyle', styleFormData)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-style-sidebar-active', false)
          resetForm()
        })
    }

    const refreshBrandOptions = brand => {
      collectionFilteredOptions.value = props.collectionOptions
        .filter(option => (option.brand === brand))
      brandContactUserFilteredOptions.value = props.brandContactUserOptions
        .filter(option => (option.brand === brand))

      styleData.value = {
        ...styleData.value,
        collection: '',
        brand_contact_user: '',
      }
    }

    if (userRole === 'brand' || userRole === 'brand-manager') {
      if (props.brandOptions.lenght > 0) {
        refreshBrandOptions(props.brandOptions[0].value)
      }
    }

    const refreshManufacturerOptions = manufacturer => {
      manufacturerContactUserFilteredOptions.value = props.manufacturerContactUserOptions
        .filter(option => (option.manufacturer === manufacturer))

      styleData.value = {
        ...styleData.value,
        manufacturer_contact_user: '',
      }
    }

    if (userRole === 'manufacturer' || userRole === 'manufacturer-manager') {
      if (props.manufacturerOptions.lenght > 0) {
        refreshManufacturerOptions(props.manufacturerOptions[0].value)
      }
    }

    const refreshAgentOptions = agent => {
      agentContactUserFilteredOptions.value = props.agentContactUserOptions
        .filter(option => (option.agent === agent))

      styleData.value = {
        ...styleData.value,
        agent_contact_user: '',
      }
    }

    if (userRole === 'agent' || userRole === 'agent-manager') {
      if (props.agentOptions.lenght > 0) {
        refreshAgentOptions(props.agentOptions[0].value)
      }
    }

    return {
      styleData,
      userRole,
      onSubmit,

      collectionFilteredOptions,
      agentContactUserFilteredOptions,
      brandContactUserFilteredOptions,
      manufacturerContactUserFilteredOptions,

      refFormObserver,
      getValidationState,
      resetForm,
      refreshAgentOptions,
      refreshBrandOptions,
      refreshManufacturerOptions,
      size,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
